import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { GrClose } from 'react-icons/gr';
import { MdInfoOutline } from 'react-icons/md';
import { useFormat } from 'helpers/hooks/useFormat';
import { generateDayOptions, generateMonthOptions, generateYearOptions } from 'helpers/utils/generateDatesOptions';

export const ForgotUserName = ({
  handleVerifySSN,
  setReceivedOTP,
  resetRadioButtons,
  loading,
  isEmpty,
  setShowSsn,
  resetUsername,
  invalidSsn,
  errorInvalidSsn,
  customer,
  backToLogin,
  setInformationRequired,
  showSsn,
  customerData,
  handleNameChange,
  inputRef,
  handleInputFocus,
  handleInputBlur,
}) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  return (
    <div>
      <div className="flex items-center justify-between">
        {resetUsername ? (
          <div className="mb-8">
            <h3 className="absolute top-10 text-lg font-semibold text-black-900">
              {formatAccountMessage({ id: 'username.forgot', defaultMessage: 'Forgot Username' })}
            </h3>
          </div>
        ) : (
          <div className="mb-8">
            <h3 className="absolute top-10 text-lg font-semibold text-black-900">
              {formatAccountMessage({ id: 'Forgot.password', defaultMessage: 'Reset Password' })}
            </h3>
          </div>
        )}
        <button type="button" className="absolute right-6 top-10" onClick={backToLogin} aria-label="Close Button">
          <GrClose />
        </button>
      </div>
      <div className="my-5 flex items-start pt-3">
        <p className="mr-2">
          <MdInfoOutline />
        </p>
        {!invalidSsn ? (
          <div>
            <p className="w-4/5 text-sm font-semibold text-black-300">
              {formatAccountMessage({
                id: 'validate.customer',
                defaultMessage: 'We must first validate that you are an authorized Exchange customer',
              })}
            </p>
          </div>
        ) : (
          <div>
            <p className="mb-4 w-4/5 text-sm font-semibold text-black-300">
              {formatAccountMessage({
                id: 'validate.customerfullSSN',
                defaultMessage: 'We must first validate that you are an authorized Exchange customer',
              })}
            </p>
            {errorInvalidSsn && (
              <p className="mt-3 w-4/5 text-sm font-semibold text-red-600 ">{customer?.body?.errorMessage}</p>
            )}
            {errorInvalidSsn && !customer?.body?.errorMessage && (
              <ul className="list-disc ">
                <li className="mt-3 w-4/5 text-sm font-semibold text-red-600 "> {customer?.body}</li>
              </ul>
            )}
          </div>
        )}
      </div>
      <div>
        <button
          type="button"
          className="mb-5 cursor-pointer font-medium text-[#043C63]"
          onClick={() => setInformationRequired(true)}
          aria-label="Learn why information is needed"
        >
          {' '}
          {formatAccountMessage({ id: 'whyneed', defaultMessage: 'Why do I need this?' })}
        </button>
      </div>

      <div className="mb-5">
        {!invalidSsn ? (
          <label className="mb-2 block text-affes-sm font-bold text-gray-800" htmlFor="ssn">
            {formatAccountMessage({ id: 'SSN.4digit', defaultMessage: 'Last four digits of SSN' })}
            <span className="ml-2 text-red-600" aria-hidden="true">
              {' '}
              {formatAccountMessage({ id: 'mandatory', defaultMessage: '*' })}
            </span>
          </label>
        ) : (
          <label className="mb-2 block text-affes-sm font-bold text-gray-800" htmlFor="ssn">
            {formatAccountMessage({ id: 'SSN.9digit', defaultMessage: 'Full SSN Number' })}{' '}
            <span className="ml-2 text-red-600" aria-hidden="true">
              {' '}
              {formatAccountMessage({ id: 'mandatory', defaultMessage: '*' })}
            </span>
          </label>
        )}
        {!invalidSsn ? (
          <div className="relative w-[215px] rounded-sm border border-gray-600 focus:border focus:border-none focus:border-gray-900 xl:w-2/4">
            <input
              id="ssn"
              name="ssn"
              type={showSsn ? 'number' : 'password'}
              className="w-[120px] appearance-none rounded-sm border-0 outline-none placeholder:text-gray-400 focus:border-none focus:outline-none xl:w-2/4"
              placeholder="••••"
              required
              maxLength={4}
              value={customerData?.ssn}
              onInput={(e: any) => {
                const sanitizedValue = e.target.value.replace(/\D/g, '');
                e.target.value = !invalidSsn ? sanitizedValue.slice(0, 4) : sanitizedValue.slice(0, 9);
              }}
              onChange={handleNameChange}
              aria-invalid={invalidSsn ? 'true' : 'false'}
              aria-describedby={invalidSsn ? 'ssnError' : ''}
              ref={inputRef}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />

            <div className="absolute right-3 top-2 flex">
              <button
                className="flex items-center text-affes-sm"
                onClick={(event) => {
                  event.stopPropagation();
                  setShowSsn(!showSsn);
                }}
                aria-label={showSsn ? 'Hide SSN' : 'Show SSN'}
              >
                {showSsn ? <EyeOffIcon className="mr-2 h-5 w-5" /> : <EyeIcon className="mr-2 h-5 w-5" />}
                {showSsn ? 'HIDE' : 'SHOW'}
              </button>
            </div>
          </div>
        ) : (
          <div className="relative w-4/5 rounded-sm border border-gray-600 focus:border focus:border-none focus:border-gray-900">
            <input
              id="ssn"
              name="ssn"
              type={showSsn ? 'number' : 'password'}
              className="w-3/5 appearance-none rounded-sm border-0 placeholder:text-gray-400 focus:border-none focus:outline-none"
              placeholder="•••••••••"
              required
              maxLength={9}
              value={customerData?.ssn}
              onInput={(e: any) => {
                const sanitizedValue = e.target.value.replace(/\D/g, '');
                e.target.value = !invalidSsn ? sanitizedValue.slice(0, 4) : sanitizedValue.slice(0, 9);
              }}
              onChange={handleNameChange}
              aria-invalid={invalidSsn ? 'true' : 'false'}
              aria-describedby={invalidSsn ? 'ssnError' : ''}
              ref={inputRef}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />

            <div className="absolute right-3 top-2 flex">
              <button
                className="flex items-center text-affes-sm "
                onClick={(event) => {
                  event.stopPropagation();
                  setShowSsn(!showSsn);
                }}
                aria-label={showSsn ? 'Hide SSN' : 'Show SSN'}
              >
                {showSsn ? <EyeOffIcon className="mr-2 h-5 w-5" /> : <EyeIcon className="mr-2 h-5 w-5" />}
                {showSsn ? 'HIDE' : 'SHOW'}
              </button>
            </div>
          </div>
        )}
        {isEmpty && !invalidSsn && customerData?.ssn.length === 0 && (
          <div className="text-red-600" id="ssnError">
            {formatAccountMessage({ id: 'last.4digit.ssn', defaultMessage: 'Enter last 4 digits of SSN' })}
          </div>
        )}{' '}
      </div>

      <div>
        <label className="mb-2 block text-affes-sm font-bold text-gray-800" htmlFor="dateOfBirth">
          {formatAccountMessage({ id: 'dateofbirth', defaultMessage: 'Date of birth' })}
          <span className="ml-2 text-red-600" aria-hidden="true">
            {' '}
            {formatAccountMessage({ id: 'mandatory', defaultMessage: '*' })}
          </span>
        </label>
        <div className="flex">
          <div className="relative">
            <select
              id="day"
              className="select w-[91px] rounded-[3px] text-[13px] font-normal leading-[16.8px] text-[#333333] xl:w-[103px] xl:text-[14px]"
              defaultValue="Day"
              name="day"
              onChange={handleNameChange}
              aria-label="Day of birth"
            >
              <option disabled>{formatAccountMessage({ id: 'day', defaultMessage: 'Day' })}</option>
              {generateDayOptions()}
            </select>
          </div>
          <div className="relative ml-2">
            <select
              id="month"
              className="select w-[91px] rounded-[3px] text-[13px] font-normal leading-[16.8px] text-[#333333] xl:w-[103px] xl:text-[14px]"
              defaultValue="Month"
              name="month"
              onChange={handleNameChange}
              aria-label="Month of birth"
            >
              <option disabled>{formatAccountMessage({ id: 'month', defaultMessage: 'Month' })}</option>
              {generateMonthOptions()}
            </select>
          </div>
          <div className="relative ml-2">
            <select
              id="year"
              className="select w-[91px] rounded-[3px] text-[13px] font-normal leading-[16.8px] text-[#333333] xl:w-[103px] xl:text-[14px]"
              defaultValue="Year"
              name="year"
              onChange={handleNameChange}
              aria-label="Year of birth"
            >
              <option disabled>{formatAccountMessage({ id: 'year', defaultMessage: 'Year' })} </option>
              {generateYearOptions()}
            </select>
          </div>
        </div>
        {isEmpty &&
          !invalidSsn &&
          (customerData?.day === '' || customerData?.month === '' || customerData?.year === '') && (
            <div className="text-red-600">
              {formatAccountMessage({ id: 'enter.valid.DOB', defaultMessage: 'Enter a valid date of birth.' })}
            </div>
          )}
      </div>
      {!invalidSsn && (
        <div className="mt-4 ">
          <label className="mb-2 block text-affes-sm font-bold text-gray-800" htmlFor="lastName">
            {formatAccountMessage({ id: 'lastname', defaultMessage: 'Last name' })}
            <span className="ml-2 text-red-600" aria-hidden="true">
              {' '}
              {formatAccountMessage({ id: 'mandatory', defaultMessage: '*' })}
            </span>
          </label>
          <input
            id="lastName"
            name="lastName"
            type="text"
            className="relative w-full rounded-sm border border-gray-600 focus:border focus:border-gray-900"
            required
            onChange={handleNameChange}
            value={customerData.lastName}
            aria-label="Last name"
            ref={inputRef}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
        </div>
      )}
      {isEmpty && !invalidSsn && customerData?.lastName.length === 0 && (
        <div className="text-red-600">
          {formatAccountMessage({ id: 'enter.last.name', defaultMessage: 'Please enter Last Name.' })}
        </div>
      )}

      <div className="mt-5 pt-10">
        <div>
          <button
            type="button"
            className="text-md mb-8 flex w-full justify-center rounded-sm border border-transparent bg-blue-900 py-2 px-4 font-medium text-white shadow-sm transition-colors duration-200 ease-out  focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:bg-gray-200"
            disabled={
              loading ||
              isEmpty ||
              (invalidSsn && customerData.ssn.length !== 9) ||
              (!invalidSsn && customerData.ssn.length !== 4) ||
              customerData.day === '' ||
              customerData.month === '' ||
              customerData.year === '' ||
              customerData.lastName === ''
            }
            onClick={handleVerifySSN}
          >
            {formatAccountMessage({ id: 'continue', defaultMessage: 'Continue' })}
          </button>
        </div>
        <div>
          <button
            type="submit"
            className="text-md flex w-full justify-center border border-transparent py-2 px-4 font-medium text-accent-400 transition-colors duration-200 ease-out focus:outline-none  disabled:bg-gray-200"
            disabled={loading}
            onClick={() => {
              backToLogin();
              resetRadioButtons();
              setReceivedOTP('');
            }}
          >
            {formatAccountMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
          </button>
        </div>
      </div>
    </div>
  );
};
